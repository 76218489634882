import {useState, useEffect} from 'react';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

const usePosition = (watch = false, settings = defaultSettings) => {
    const [position, setPosition] = useState({});
    const [errorPos, setError] = useState(null);

    const onChange = ({coords, timestamp}) => {
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
            speed: coords.speed,
            timestamp,
        });
    };

    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        let watcher = null;
    // Do we already have it in sessionStorage?
        if (sessionStorage && sessionStorage.getItem("aw_position")) {
         
            let positionValue = JSON.parse(sessionStorage.getItem('aw_position'));
//            console.log('aw pos ', positionValue);
            let lat = parseFloat(positionValue.lat);
            let lng = parseFloat(positionValue.lng);
            setPosition(position => (
                {
                    ...position,
                    latitude: lat,
                    longitude: lng
                }
            ));

        } else {
      //console.log('use position');
            if (!navigator || !navigator.geolocation) {
                // console.log('use position Geolocation is not supported');
                setError('use position Geolocation is not supported');
                return;
            }
            
            if (watch) {
                watcher =
                navigator.geolocation.watchPosition(onChange, onError, settings);
            } else {
                navigator.geolocation.getCurrentPosition(onChange, onError, settings);
            }
    
        }
    
        return () => watcher && navigator.geolocation.clearWatch(watcher);
        
    }, [
        settings,
        settings.enableHighAccuracy,
        settings.timeout,
        settings.maximumAge,
        watch
    ]);

    return { ...position, errorPos };
    
};

export default usePosition;