import React, { Component } from 'react';
import './style.scss';
import { LanguageContext } from '../../providers/LanguageProvider';
import { isDesktop } from '../../shared/helpers';
import NavMenu from '../../components/NavBar/NavMenu/NavMenu';
import MoreMenu from '../../components/MoreMenu/MoreMenu';

class Header extends Component {
    state = {
        lang: '',
        isDesktop: isDesktop(),
        moreMenuShow: false
    }

    // Grab the context!
    static contextType = LanguageContext;

    componentDidMount(){
         
            // Set the Lang State
            this.setState({
                lang: this.context.language
            })
        
    }

    toggleDropMenu = (e) => {
        
        this.setState((prevState) => {
            return {
                moreMenuShow: !prevState.moreMenuShow
            }
        })
    }

    render(){
        let { moreMenuShow } = this.state;
        let moreMenuStatus = moreMenuShow ? 'more-menu-show' : '';

        return (
            <div className={`blockwrap header-wrap ${moreMenuStatus}`}>
                <header className="header">
                    {/* <NavBar {...this.props} /> */}
                    <NavMenu {...this.props} moreMenuShow={moreMenuShow} toggleDropMenu={this.toggleDropMenu}/>
                </header>
                <MoreMenu {...this.props} moreMenuStatus={moreMenuStatus} moreMenuShow={moreMenuShow}  />
            </div>
    
        )
    }
    
}
export default Header;

