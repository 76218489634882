import React from 'react';
import './style.scss';
import Icon from '../../../../components/Icon/Icon';
import { tl_data } from '../translations.js';

const phoneLink = (number) => {
    number = number.replace(/\(/, '');
    number = number.replace(/\)/, ' ');
    var link = 'tel:' + number.replace(/ /g, '');
    return <a href={link}><Icon type="phone" />{number}</a>;
}

const Contact = (props) => {
    
    return(

        <div className="restaurant-location-contact ">
            <h3>{tl_data['Contact'][props.lang]}</h3>
            <p>
                {phoneLink(props.location.phone_number)}
                {/* <a className="apply-btn" href={"https://www.aw.ca/awhome" + (props.lang == 'fr' ? '_FR' : '') + ".nsf/JobCitySearch?openform&ID=" + props.location.province_code + "_" + props.location.city_name + '&RESTAURANTNO=' + props.location.restnum}>{tl_data['Apply'][props.lang]} <Icon type="gt-inverted" /></a> */}
                <a className="apply-btn" href={props.lang !== 'fr' ? 'https://jobs.higherme.com/aw-en' : 'https://emplois.higherme.com/aw-fr'} target="_blank" rel="noreferrer">{tl_data['Apply'][props.lang]} <Icon type="gt-inverted" /></a>
            </p>
        </div>

    )
}
export default Contact;
