import React, { Component } from 'react';
import './style.scss';
//import LocationHeader from '../../containers/LocationHeader/LocationHeader';
//import OptionsHeader from '../../containers/OptionsHeader/OptionsHeader';
//import AnnouncementBar from '../../components/AnnouncementBar/AnnouncementBar';
import OptionsHeader from '../../containers/OptionsHeader/OptionsHeader';
import Header from '../../containers/Header/Header';
import Footer from '../../components/Footer/Footer';
import Stroll from '../../components/Stroll/Stroll';
import ExploreMenu from '../../components/ExploreMenu/ExploreMenu';

class Wrapper extends Component {

    shouldComponentUpdate = (prevProps) => {
        if(prevProps.children !== this.props.children){
            //console.log('updated wrapper component');
            return true
        }
        return false;
    }

    render(){
        let { children, addClass, exploreMenu } = this.props;
        return (

            <div className={`wrapper ${addClass}`}>
                {/* <OptionsHeader {...this.props} /> */}
                {/* <AnnouncementBar {...this.props} /> */}
                <OptionsHeader {...this.props} />
                <Header {...this.props}/>
                {exploreMenu && <ExploreMenu {...this.props} />}
                <Stroll>
                    {children}
                     <Footer {...this.props} />
                </Stroll>
              
            </div>
        )
    }
    
}
export default Wrapper;