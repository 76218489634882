import React from 'react';
import Helmet from 'react-helmet';

const MetaInfo = (props) => {

    return (
        <>
            {props.lang !== 'fr' ?
                <Helmet>
                    <title>Locations at A&W Canada - Restaurants all across Canada.</title>
                    <meta name="description" content="From East to West and everywhere in between, we’ve got restaurants ready to serve you tasty burgers, onion rings and frosty mugs of Root Beer." />
                    <link rel="canonical" href={props.url} />
                </Helmet>
            :
                <Helmet>
                    <title>Trouver un restaurant A&W Canada - Des restaurants partout à travers le pays.</title>
                    <meta name="description" content="D’est en ouest, on a des restaurants prêts à vous servir de savoureux burgers, des rondelles d’oignon et un bock de Root Beer givré." />
                    <link rel="canonical" href={props.url} />
                </Helmet>
            }
        </>
    )
}
export default MetaInfo;