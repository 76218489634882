import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { tl } from '../../shared/helpers';
import Icon from '../Icon/Icon';
import { tl_data } from './translations.js';
import Helmet from 'react-helmet';
import faqBg from './i/faq.jpg';
import contactUsBg from './i/contact-us.jpg';
import giftCardsBg from './i/gift-cards.jpg';
import careersBg from './i/careers.jpg';
import { CSSTransition } from 'react-transition-group';

const MoreMenu = (props) => {
    return (       
        <React.Fragment>
        <Helmet>
            <html className={props.moreMenuStatus} />
        </Helmet>

        <div className={`more-menu-wrap more-menu-show`}>
           
            <CSSTransition
                in={props.moreMenuShow}
                timeout={400}
                classNames="fade"
                unmountOnExit
                appear
            >
                <nav className='more-menu'>
                <ul>
                    <li className="careers-menu-item" style={{ backgroundImage: `url(${careersBg}`}}>
                        <a className="full careers" target="_blank" rel="noreferrer" href={props.lang !== 'fr' ? 'https://jobs.higherme.com/aw-en' : 'https://emplois.higherme.com/aw-fr'}> {tl(tl_data, "Work with Us", props.lang)} <Icon type="angled-arrow-inverted" /></a>
                    </li>
                    <li className="faq-menu-item" style={{ backgroundImage: `url(${faqBg}`}}>
                        <Link to={`/${props.lang}/faq`} className="full faq">
                            {tl(tl_data, "FAQ", props.lang)} <Icon type="gt-inverted" />
                        </Link>
                    </li>
                    <li className="contact-us-menu-item" style={{ backgroundImage: `url(${contactUsBg}`}}>   
                        <Link to={`/${props.lang}/contact-us`} className="full contact">
                            {tl(tl_data, "Contact Us", props.lang)} <Icon type="gt-inverted" />
                        </Link>
                    </li>
                    <li className="gift-cards-menu-item" style={{ backgroundImage: `url(${giftCardsBg}`}}>                   
                        <Link to={`/${props.lang}/gift-cards`} className="full gift-cards">
                            {tl(tl_data, "Gift Cards", props.lang)} <Icon type="gt-inverted" />
                        </Link>
                    </li>
                </ul>
                </nav>
            </CSSTransition>
            
        </div>      
        </React.Fragment>
    )
}
export default MoreMenu;