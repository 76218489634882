// // Gets the whole list
// this.getAll = function(callback = null) {
//     if (this._all) {
//         if (callback !== null)
//             callback(this.locations);
//         return; 
//     }
    
//     var o = this;
//     fetch(helpers.api_location + '/api/locations/')
//         .then(function(response) {
//             return response.json();
//         }).then(function(json) {
//             for(var i = 0; i < json.length; i++)
//                 o._add(json[i]);
//             o._all = true;
//             if (callback != null)
//                 callback(json);
//         });
// };

import axios from 'axios';
const API_URL =  process.env.REACT_APP_API_URL;
let afterHours;

export const getAll = (callback = null) => {
    // if in localStorage return location from localstorage
    if(sessionStorage && sessionStorage.getItem('aw_all_locations')){
        let locations = JSON.parse(sessionStorage.getItem('aw_all_locations'))
        if (callback !== null)
            callback(locations);
        return; 
    }else{
        axios.get(`${API_URL}/api/locations/`)
                .then((response) => {
                    // Set Local Storage
                    if(sessionStorage){
                        sessionStorage.setItem("aw_all_locations", JSON.stringify(response.data))
                    }
                    callback(response.data)
                    
                })
                .catch(error => {
                    console.log('oops, you\'re error is: ', error);
                    callback(error)
                })
    }
}

export const target = function(locations, location) {
    //console.log('restau locations + pos',locations, location);
    for(var num in locations) {
        var r = locations[num];
        var d = utils.getDistance(location, r);
        r.distance = d;
    }
    return utils.sort(locations);
    // const sort = (locations) => {
    //     var sorted = [];
    //     for(var prop in locations)
    //         sorted.push(locations[prop]);
    //     sorted.sort(function(a, b) {
    //         if (a.distance < b.distance) return -1;
    //         if (a.distance > b.distance) return 1;
    //         return 0;
    //     });
    //     return sorted;
    // }
    // return sort;
    
   
};

export const distanceOut = (d) => {
    let distance = (Math.round(d / 100) / 10);
    distance = (isNaN(distance) ? '' : distance );
    return distance;
}

export const is24hourDriveThru =  (location) => {
    return location.drive_thru_open_24_hours === 'Y';
};

export const isOnlyDriveThru = (location) => {
    utils.open(location, false);
    
    if(is24hourDriveThru(location) && afterHours){
        return true;
    } 
}

export const brewbar =  (location) => {
    return location.brewbar === 'Y';
};

export const isRestaurantOpen = (location) => { 
    const is24hours = (location) => {
		return location.inside_open_24_hours === 'Y' || location.drive_thru_open_24_hours === 'Y';
	};
	
    if (is24hours(location)) 
        return true;
    else
        return utils.open(location, true);
};

export const applicableHours = (location) => {
    return utils.open(location, false);
};

export const hasHours = (location) => {
    for(var i=0; i < location.hours.length; i++)
        if (location.hours[i] !== "-")
            return true;
    return false;
}

// export const target = (location) => {
//     for(var num in this.locations) {
//         var r = this.locations[num];
//         var d = utils.getDistance(location, r);
//         r.distance = d;
//     }
//     return utils.sort(this.locations);
// };

let utils = (() => {
    return {
        rad:  function(x) {
            return x * Math.PI / 180;
        },
    
        latLngWrap: function(a) {
            return {
                lat: function() {
                    return a.lat;
                },
                lng: function() {
                    return a.lng;
                }
            };
        },

        _getDistance:  function(p1, p2) {
            var R = 6378137; // Earth's mean radius in meter
            var dLat = this.rad(p2.lat() - p1.lat());
            var dLong = this.rad(p2.lng() - p1.lng());
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) *
                Math.sin(dLong / 2) * Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d; // returns the distance in meter
        },
    
    
        getDistance: function(p1, p2) {
            return this._getDistance(this.latLngWrap(p1), this.latLngWrap(p2));
        },

       parseTime: function(o) {
            var m;
            if ((m = /^^(\d+):(\d{2})(:[\d]+)? ([a-z]{2})/.exec(o.toLowerCase())) !== null) {
                var d = new Date();
                if (m[1] === '12' && m[4] === 'am') {
                    d.setHours(0);
                } else {
                    var h = parseInt(m[1]);
                    d.setHours(h + ((m[4] === 'pm' && h < 12) ? 12 : 0));
                }
                d.setMinutes(parseInt(m[2]));
                d.setSeconds(0);
    
                return d;
            }
            return false;
        },

        open: function(location, bool) {
            try {
                var currentdate = new Date(); 
                var today = currentdate.getDay();
                    
                var range = location.hours[today];
                var chunks = range.split('-');
                var opens = this.parseTime(chunks[0]);
                var closes = this.parseTime(chunks[1]);
                var midnight = this.parseTime('12:00:00 AM').getTime();
                if (closes.getTime() === midnight) {
                        if (bool)
                            return true;
                        else
                            return range;
                }
                if (closes < opens) {
                    closes.setDate(closes.getDate() + 1);
                }
                
                if (opens !== false && closes !== false) {
                    // Is the time between open & close
                    if (currentdate >= opens && currentdate <= closes) {
                        
                        if (bool)
                            return true;
                        else
                            return range;
                    } else{
                        afterHours = true;
                    }
                }
                if (bool)
                    return false;
                else
                    return location.hours[today];
            } catch (e) {
                //console.log(e);
                return bool ? false : "N/A";
            }
        },
        
        sort:  function(locations) {
            var sorted = [];
            for(var prop in locations)
                sorted.push(locations[prop]);
            sorted.sort(function(a, b) {
                if (a.distance < b.distance) return -1;
                if (a.distance > b.distance) return 1;
                return 0;
            });
            return sorted;
        }
    }
})();



// var utils = function () {
//     var rad = function(x) {
//         return x * Math.PI / 180;
//     };

//     var latLngWrap = function(a) {
//         return {
//             lat: function() {
//                 return a.lat;
//             },
//             lng: function() {
//                 return a.lng;
//             }
//         };
//     };
    
//     var _getDistance = function(p1, p2) {
//         var R = 6378137; // Earth's mean radius in meter
//         var dLat = rad(p2.lat() - p1.lat());
//         var dLong = rad(p2.lng() - p1.lng());
//         var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//             Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
//             Math.sin(dLong / 2) * Math.sin(dLong / 2);
//         var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//         var d = R * c;
//         return d; // returns the distance in meter
//     };


//     this.getDistance = function(p1, p2) {
//         return _getDistance(latLngWrap(p1), latLngWrap(p2));
//     };
    
    
    
//     this.parseTime = function(o) {
//         var m;
//         if ((m = /^^(\d+):(\d{2})(:[\d]+)? ([a-z]{2})/.exec(o.toLowerCase())) !== null) {
//             var d = new Date();
//             if (m[1] == '12' && m[4] == 'am') {
//                 d.setHours(0);
//             } else {
//                 var h = parseInt(m[1]);
//                 d.setHours(h + ((m[4] == 'pm' && h < 12) ? 12 : 0));
//             }
//             d.setMinutes(parseInt(m[2]));
//             d.setSeconds(0);

//             return d;
//         }
//         return false;
//     };
    
//     this.hasHours = function(location) {
//         for(var i=0; i < location.hours.length; i++)
//             if (location.hours[i] != "-")
//                 return true;
//         return false;
//     };
    
//     this.open = function(location, bool) {
//         try {
//             var currentdate = new Date(); 
//             var today = currentdate.getDay();
                
//             var range = location.hours[today];
//             var chunks = range.split('-');
//             var opens = this.parseTime(chunks[0]);
//             var closes = this.parseTime(chunks[1]);
//             var midnight = this.parseTime('12:00:00 AM').getTime();
//             if (closes.getTime() == midnight) {
//                     if (bool)
//                         return true;
//                     else
//                         return range;
//             }
//             if (closes < opens) {
//                 closes.setDate(closes.getDate() + 1);
//             }
            
//             if (opens !== false && closes !== false) {
//                 // Is the time between open & close
//                 if (currentdate >= opens && currentdate <= closes) {
                    
//                     if (bool)
//                         return true;
//                     else
//                         return range;
//                 } else{
//                     let afterHours = true;
//                 }
//             }
//             if (bool)
//                 return false;
//             else
//                 return location.hours[today];
//         } catch (e) {
//             //console.log(e);
//             return bool ? false : "N/A";
//         }
//     };
    
//     this.sort = function(locations) {
//         var sorted = [];
//         for(var prop in locations)
//             sorted.push(locations[prop]);
//         sorted.sort(function(a, b) {
//             if (a.distance < b.distance) return -1;
//             if (a.distance > b.distance) return 1;
//             return 0;
//         });
//         return sorted;
//     };
// };