import React from 'react';
import './style.scss';
import Icon from '../../../../components/Icon/Icon';
import { tl_data } from '../translations.js';
import { TranslatableText } from '../../../../providers/LanguageProvider';

const dayName = (i) => {
	return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][i];
}

const timeRange = (str, lang) => {
    var chunks = str.split("-");
    return time(chunks[0].trim(),lang) + ' - ' + time(chunks[1].trim(),lang);
};

export const time = (str, lang) => {
    var m = str.toLowerCase().match(/([\d]{2}):([\d]{2}):[\d]{2} ([a-z]{2})/);
    if (m == null) return '';
    switch(lang) {
        case 'en':
            return parseInt(m[1]) + ':' + m[2] + m[3];
        case 'fr':
            // remove leading 0 from hour and store in str
            str = parseInt(m[1], 10);
            
            if (m[3] === 'pm') {
                str = (parseInt(m[1]) + 12) + '';
                if (str.length < 2)
                    str = '0' + str;
            }
            // if minutes is 00 (ie 12 h 00), then remove it, otherwise return the actual minutes (ie 12 h 30)
            if(m[2] === '00') {
                return str + ' h';
            } else {
                return str + ' h ' + m[2];
            }
            default : console.log('no language');
    }

    return str;
};

const Hours = (props) => {
    let today = new Date().getDay(); 
    let hoursRow = null;
    let driveThru24Hours = null;

    if(props.location.hours){
        hoursRow = props.location.hours.map((hours,i)=> {
            let classes = (i === today ? 'today' : '');
            //console.log('hours ',hours);
            return (
                <tr key={i} className={classes}>
                    <th>
                        {dayName(i)}
                    </th>
                    <td>{timeRange(hours, props.lang)}</td>
                </tr>
            )
        })

        driveThru24Hours = props.location.drive_thru_open_24_hours === 'Y' && <div className="drive-thru-24hrs"><Icon type='front-car' /> <TranslatableText dictionary={{
                "en": "Drive-Thru Open 24 hours",
                "fr": "Service au volant ouvert 24 heures."
            }}
        >
            </TranslatableText></div>;
       
    }

    return (

        <div className="hours-of-operation">
            <h3>{tl_data['Hours of Operation'][props.lang]}</h3>
            <table>
                <tbody>
			        {hoursRow}
			    </tbody>
            </table>
            {driveThru24Hours}
        </div>

    )
}
export default Hours;