import React, {useState, useEffect, createContext } from 'react';
import usePosition from '../../hooks/usePosition/usePosition';
import Loading from '../../components/Loading/Loading';

const LocationContext = createContext();

const setLocation = (r, callback) => {
    let loc = {
        lat: parseFloat(r.location.latitude),
        lng: parseFloat(r.location.longitude)
    };
    //this._region = r.subdivisions[0].iso_code;
    let region = r.subdivisions[0].iso_code;
    let position = loc;

    // Set locale and latLng in localStorage
    if(sessionStorage){
        sessionStorage.setItem('aw_locale', JSON.stringify(region));
        sessionStorage.setItem('aw_position', JSON.stringify(position));
    }

    if (callback) {
        callback(loc);
    }
}

const LocationContextProvider = ({ children, browserLocate }) => {

    //const [region, setRegion] = useState('');
    const [userGeoPosition, setUserGeoPosition ]= useState({
        lat: null,
        lng: null
    });
    
    const [loadingPos, setLoadingPos] = useState(true);

    const { latitude, longitude, errorPos } = usePosition(true);
    
    const getMaxPosition = (callback = null, onError = null) => {
		
        // Check for localStorage position or this.objects position... 
        if (sessionStorage && sessionStorage.getItem('aw_position')) {
            let position = JSON.parse(sessionStorage.getItem('aw_position'));
            if (callback !== null)
                //console.log('session storage ', position);
                callback(position);
                return;
        }
        
        // Add retry method later
        if (window.geoip2) {
            //console.log('getPosition MaxMind');
            window.geoip2.city((r) => setLocation(r,callback), onError);
            return true;
        } else {
            const fallbackGeoPosition = { lat: 54.1452142, lng: -107.9139848 };
            callback(fallbackGeoPosition);
            return false;
           
        }
    
    }

     // Listen for latitude, longitude changes
    // Set a default position in case we can't load anything
    useEffect(() => {
        // update the userPosition based on the usePosition hook 
       
        if (latitude) {
            //console.log('use effect lat lng ', latitude);
            let userPos = { lat: latitude, lng: longitude };
            setUserGeoPosition(userPos);
            setLoadingPos(false);
            if (sessionStorage) {
                sessionStorage.setItem('aw_position', JSON.stringify(userPos));
            }
        }
        

    }, [latitude, longitude]);


    useEffect(() => {

        if (errorPos) {
            // Fallback center of Canada.
            const fallbackGeoPosition = { lat: 54.1452142, lng: -107.9139848 };
            // console.log('browser location error ', errorPos);
            // If max sucess, setUserGeoPosition,
            getMaxPosition((pos) => {
                // console.log('returned max mposition ', pos);
                setUserGeoPosition(pos);
                setLoadingPos(false);
                if (sessionStorage) {
                    sessionStorage.setItem('aw_position', JSON.stringify(pos));
                }
               
            }, (err) => {
                    // Can't find geo lat & lng position. Set to default center of canada.
                    setUserGeoPosition(fallbackGeoPosition);
                    setLoadingPos(false);
                    if (sessionStorage) {
                        sessionStorage.setItem('aw_position', JSON.stringify(fallbackGeoPosition));
                    }
            })

        }

    }, [ errorPos ]);

    return (
        <>
            <LocationContext.Provider value={{userGeoPosition, loadingPos}}>
                {loadingPos ? <Loading /> : children}
            </LocationContext.Provider>
        </>
    )
}

export { LocationContext, LocationContextProvider }