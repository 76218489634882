import React, { Component } from 'react';
import Spinner from 'react-spinner-material';
import './style.scss';

class Loading extends Component {
  render() {
  return (
      <div className="loading-wrap">
        <Spinner size={180} spinnercolor={"#ff6218"} spinnerwidth={2} visible={true} />
      </div>
    );
  }
}
export default Loading;