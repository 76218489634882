export const data = {
  "find_aw_near_you": {
    "en": "Find an A&W",
    "fr": "Trouvez un A&W près de vous"
  },
  "find_aw_near_you_mobile": {
    "en": "Find an A&W",
    "fr": "Trouvez un A&W"
  },
  "get_our_app": {
    "en":"Get our app",
    "fr":"Téléchargez notre appli"
  },
  "get_our_app_mobile": {
    "en":"Get our app",
    "fr":"Téléchargez appli"
  },
  "order_delivery": {
    "en": "Order Delivery",
    "fr": "Commander"
  },
  "order_delivery_mobile": {
    "en": "Order Delivery",
    "fr": "Commander"
  }
}
