import React from 'react';
import './style.scss';
//import Icon from '../../../../components/Icon/Icon';
import {tl_data} from '../translations.js';

import DriveThruIcon from './i/SvgDriveThruIcon/SvgDriveThruIcon';
import HoursIcon from './i/SvgHoursIcon/SvgHoursIcon';
import BrewBarIcon from './i/SvgBrewBarIcon/SvgBrewBarIcon';

const Amenities = (props) => {
    let is24HRS     = props.location.inside_open_24_hours === 'Y' ? true : false;
    let isDriveThru = props.location.drive_thru === 'Y' ? true : false;
    let isBrewBar = props.location.brewbar === 'Y' ? true : false;
    
    return (

        <div className="amenities">
  
  
            <h3>{tl_data['Amenities'][props.lang]}</h3>
            <ul>
                { is24HRS && <li><HoursIcon fill="#4b3327" /> {tl_data['Open 24 hours'][props.lang]}</li> }
                { isDriveThru && <li><DriveThruIcon fill="#4b3327" /> {tl_data['Drive-Thru'][props.lang]}</li> }
                { isBrewBar && <li><BrewBarIcon fill="#4b3327" /> {tl_data['Brew Bar'][props.lang]}</li>}
            </ul>
           
        </div>

)}
export default Amenities;