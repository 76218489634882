import React, { Component } from 'react';
import './style.scss';
import classie from 'classie';

class Stroll extends Component {
    state = {
        handler: null
    }
    myRef = React.createRef();

    componentDidMount = () => {
        //console.log('my ref ',this.myRef);
        new window.AnimOnScroll( document.getElementById( 'grid' ), {
            minDuration : 0.4,
            maxDuration : 0.7,
            viewportFactor : 0.01
        },classie );
        
	}
    render() {
        var classes = [this.props.className, "strolling"];
        
        let contentItems = this.props.children.map((item,index) => {
            
            return (
                <div key={index}>{item}</div>
            )
        })

		return (
			<section className={classes.join(" ")} ref={this.myRef} id="grid">
                {contentItems}
            </section>
		);
    }
}

export default Stroll;