let geoloca = (() => {
    // let position = false;
	// let browser = false;
    // let region = false;

    return {

        getRegion: (callback = null, onError = null) => {
            // Check local storage for Region
            if(sessionStorage && sessionStorage.getItem('aw_locale')){
                let locale = JSON.parse(sessionStorage.getItem('aw_locale'));
                if(callback)
                    callback(locale);
                    return;
            }else{
                // Check for geoIP
                if(window.geoip2){
                    window.geoip2.insights((loc) => geoloca.filterRegion(loc,callback), onError)
                }
                
            } 
        },

        filterRegion: (location, callback) => {
            console.log('filter location ',location.subdivisions[0].iso_code)
            let region = location.subdivisions[0].iso_code;
            let canadian = region.match(/^(AB|BC|MB|NB|NL|NS|NT|NU|ON|PE|QC|SK|YK)$/);
    
            if(canadian){
                // Set LocalStorage if available
                if(sessionStorage){
                    sessionStorage.setItem('aw_locale', JSON.stringify(region));
                }
                if(callback)
                    callback(region);
                    return;
                
            }else{
                console.log('not a canadian region ');
                // Set Region to default - ON 
                if(sessionStorage){
                    sessionStorage.setItem('aw_locale','ON');
                }
    
                if(callback)
                    callback('ON');
               
            }
        },

        getPosition: (callback = null) => {

            let error = (mes) => {
                console.log('error is ',mes);
                geoloca.getMaxPosition(callback);
            }

            let success = (pos, callback) => {
                console.log('position is ',pos);
                var p = {
                    		lat: pos.coords.latitude,
                    		lng: pos.coords.longitude
                        };
                if (callback)
                    callback(p);
            }
            // Try to use browswer geolocaction
            if (navigator.geolocation) {

                navigator.geolocation.getCurrentPosition((pos) => success(pos, callback),(mes) => error(mes),{
					enableHighAccuracy: false,
					timeout: 5000,
					maximumAge: 0
				});
				return false;

				// navigator.geolocation.getCurrentPosition(function(pos) {
				// 	var p = {
				// 		lat: pos.coords.latitude,
				// 		lng: pos.coords.longitude
				// 	};
                //     this._browser = p;
                //     // Set localStorage
				// 	done();
				// }.bind(this), function(o) {
				// 	geolocFail();
				// }.bind(this), {
				// 	enableHighAccuracy: false,
				// 	timeout: 5000,
				// 	maximumAge: 0
				// });
				// return false;
			} else {
                console.log("Geolocation is not supported by this browser.");
                // Fallback to the maxmind IP based one
				geoloca.getMaxPosition(callback); 
				return false;
			}
        },

        getMaxPosition: (callback = null, onError = null) => {
		
            // Check for localStorage position or this.objects position... 
            if (sessionStorage && sessionStorage.getItem('aw_position')) {
                let position = JSON.parse(sessionStorage.getItem('aw_position'));
                if (callback !== null) 
                    callback(position);
                    return;
            }
            
            // Add retry method later
            if (window.geoip2) {
                console.log('getPosition MaxMind');
                window.geoip2.city((r) => geoloca.setLocation(r,callback), onError);
                return true;
            }else{
                return false;
            }
                
        
        },
    
        setLocation: (r, callback) => {
            let loc = {
                lat: parseFloat(r.location.latitude),
                lng: parseFloat(r.location.longitude)
            };
            //this._region = r.subdivisions[0].iso_code;
            let region = r.subdivisions[0].iso_code;
            let position = loc;
        
            // Set locale and latLng in sessionStorage
            if(sessionStorage){
                sessionStorage.setItem('aw_locale', JSON.stringify(region));
                sessionStorage.setItem('aw_position', JSON.stringify(position));
            }
    
            if (callback) {
                callback(loc);
            }
        },

        lookupLoc: (q, callback = null) => {
            //console.log('google geo lookup init');
            if (window.google && window.google.maps){
                let geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({'address': q}, function(results, status) {
                    				if (status === 'OK') {
                    					let pos = {
                    						lat: results[0].geometry.location.lat(),
                    						lng: results[0].geometry.location.lng()
                    					};
                                    // Return position     
                    					if (callback){
                                            callback(pos);	
                                        }
                    											
                    				} else {
                    					console.log('geocoding failed for ' + q);
                    					if (callback)
                    						callback(false);
                    				}
                    			});

            }
        }
        	
	/**
	 * Looks up a position using google maps geocoder
	 */
	// this.lookup = function(q, callback = null) {
	// 	//console.log('google geo lookup init');
	// 	helpers.retry(function() {
	// 		if (window.google && window.google.maps) {
	// 			var geocoder = new google.maps.Geocoder();
	// 			geocoder.geocode({'address': q}, function(results, status) {
	// 				if (status == 'OK') {
	// 					var pos = {
	// 						lat: results[0].geometry.location.lat(),
	// 						lng: results[0].geometry.location.lng()
	// 					};
						
	// 					// It's our new position
	// 					this._position = pos;
						
	// 					if (callback)
	// 						callback(pos);						
	// 				} else {
	// 					console.log('geocoding failed for ' + q);
	// 					if (callback)
	// 						callback(false);
	// 				}
	// 			}.bind(this));
	// 			return true;
	// 		}
	// 		return false;
	// 	}.bind(this), 100, 20);
	// }

        
    };

})();

export default geoloca;