import React, {Fragment, useState, useRef, useEffect, memo, useContext, useReducer } from 'react';
import './style.scss';
import { Link, Route, useParams, useLocation } from 'react-router-dom';
import { LocationContext } from '../../context/LocationContext/LocationContext';
import Wrapper from '../../templates/Wrapper/Wrapper';
import axios from 'axios';
import { convertArrayToObject } from '../../shared/helpers';
import { TranslatableText } from '../../providers/LanguageProvider';
import MetaInfo from '../Locations/MetaInfo/MetaInfo';
import List from './List/List';
import SearchBar from './SearchBar/SearchBar';
import { target, isRestaurantOpen } from '../../shared/restaurants';
import SingleLocationPanel from './SingleLocationPanel/SingleLocationPanel';
import Map from './Map/Map';
import geoloca from '../../shared/geoloca';
import MobileOrderBanner from '../../components/MobileOrderBanner/MobileOrderBanner';
const API_URL = process.env.REACT_APP_API_URL;

const fetchReducer = (state, action) => {
    switch(action.type){
        case 'SAVE_LOCATIONS': {
            //console.log('save loc ',action.payload);
            const allLocations = action.payload;  
            return  { locations: allLocations }   
        }
        case 'ERROR' : {
            return {
                ...state,
                error: action.payload
            }
        }
            
        default:
            return state
    }
}

const filterReducer = (state, action) => {
    switch(action.type){
        case 'OPEN': {
            return {
                ...state,
                open: !state.open
            }
        }
        case 'DRIVE_THRU': {
            return {
                ...state,
                drive_thru: !state.drive_thru
            }
        }
        case 'BREWBAR': {
            return {
                ...state,
                brewbar: action.payload || !state.brewbar
            }
        }
        case 'TOGGLE_ALL': {
            return {
                open: action.payload,
                drive_thru: action.payload,
                brewbar: action.payload
            }
        }

        default:
            return state
    }
}




const defaultListState = {
    locations: null,
    error: null
}
const defaultFilterState = { 
    open: false, 
    drive_thru: false, 
    o24h: false, 
    brewbar: false 
}

const Locationz = (props) => {
    //console.log('locatioz props ', props);
    const locationCtx = useContext(LocationContext);
    const infoPanelRef = useRef();
    const params = useParams();
    const location = useLocation();
    console.log('location: ', location);
    const [singleLocation, setSingleLocation] = useState(false);
    const [singleLocationInfo, setSingleLocationInfo] = useState(null);
    //console.log('single location info: ', singleLocationInfo);
    const [targetList, setTargetList] = useState(null);
    const [loadedList, setLoadedList] = useState(null);
    const [userPos, setUserPosition] = useState(null)
    // Default map center
    const [mapCenter, setMapCenter] = useState({lat: 54.1452142, lng: -107.9139848});
    const [mapZoom, setMapZoom] = useState("13");
    const [keepFilterOpen, setKeepFilterOpen] = useState(false);
    // eslint-disable-next-line
    const [query, setQuery] = useState('');
    // eslint-disable-next-line
    const [error, setError] = useState(false);

    // REDUCERS
    const [allLocations, dispatchLocations] = useReducer(fetchReducer, defaultListState);
    const [filterOptions, dispatchFilter]   = useReducer(filterReducer, defaultFilterState);
  
    // Get ALL Locations
    useEffect(() => { 

        // Check State For All Locations
        if (allLocations.locations !== null) {
           // console.log('allLocations in state memory...', allLocations.locations);
            return 
        } else {
            // Get the Locations from session storage.
            if (sessionStorage && sessionStorage.getItem('aw_all_locations')) {
                const allLocations = JSON.parse(sessionStorage.getItem('aw_all_locations'));
                //setAllLocations(allLocations);
                dispatchLocations({type: 'SAVE_LOCATIONS', payload: allLocations})
                //console.log('set all locations')
            } else {
                // Get all locations from API
                axios.get(`${API_URL}/api/locations/`)
                    .then((response) => {
                        // Transform data to required object to send to target function. 
                        //console.log('response ',response);
                        const addOpenProperty = response.data.map((location) => {
                            //console.log('location ',location);
                            return {
                                ...location,
                                open: isRestaurantOpen(location)
                            }
                        })
                        let transformedLocations = convertArrayToObject(addOpenProperty, 'restnum')
                        //console.log('axios transform obj trnzOjb ',transformedLocations); 
                        sessionStorage && sessionStorage.setItem("aw_all_locations", JSON.stringify(transformedLocations))
                        dispatchLocations({type: 'SAVE_LOCATIONS', payload: transformedLocations})
                        //return transformedLocations;
                        //setAllLocations(prev_allLocations => transformedLocations)
                    })
                    .catch(error => {
                        dispatchLocations({type: 'ERROR', payload: error})
                        //console.log('oops, you\'re error is: ', error);
                        // PUT Error handling here
                        //callback(error)
                    })
            }
            
        }
        
    });

    // Get lat lng position
    useEffect(() => { 
        let userPos = locationCtx.userGeoPosition;
        //console.log('user pos ',userPos);
        setUserPosition(userPos);
        setMapCenter(userPos);
        if (!locationCtx.loadingPos && allLocations.locations !== null) {
          
            // Set Target
            const tarList = target(allLocations.locations, userPos);
            setTargetList(tarList);
            setLoadedList(tarList);
            //setNoFilterList(tarList);
        }
    
        
    }, [locationCtx.userGeoPosition, locationCtx.loadingPos, allLocations]);

    const searchChange = (e) => {
        e.preventDefault();
        let newQuery = '';
        newQuery = e.target.value;
        //console.log('query is ',e);

        if(newQuery !== query){
            //Pass search query and callback
            geoloca.lookupLoc(newQuery + " Canada", (pos) => {

                if (pos === false) {
                    //error
                    dispatchLocations({type: 'ERROR', payload: "Something went wrong..."})
                    return;
                }
            
                let tarList = target(allLocations.locations, pos);
                toggleAllFilters(false);
                setTargetList(tarList);
                setLoadedList(tarList);
                //setUserPosition(pos)
                setMapCenter(pos)
                
            })
        }
       
    }

     // Run effect when a single location is loaded in url
     useEffect(() => {
        if (params.locationId) {
           //console.log('single location')
           setSingleLocation(true)
           infoPanelRef.current.scrollTo(0,0);
           // setSingleInfo
           if(allLocations.locations){
               Object.entries(allLocations.locations).filter(([key, value]) => {
                  
                   if(params.locationId === key){
                       // set singleLocationInfo
                       let locationPos = {lat: value.lat, lng: value.lng}
                       setSingleLocationInfo(value)
                       setMapCenter(locationPos)
                       setMapZoom("16");
                       return value

                   }
                   return value

               })
           }
          
           
       }else{
           setMapZoom("13");
           setSingleLocation(false)
       }
       
   },[singleLocation, params, allLocations])

    useEffect(() => {
        console.log('location hash ', location.hash);
        //updating if statement to check if hash includes #brewbar instead of ===, as email links now append UTM tracking data and was breaking functionality
        if (location.hash.includes('#brewbar')) {
            //console.log('SET OPTIONS FOR BREWBAR')
            dispatchFilter({ type: 'BREWBAR', payload: true})
        } 
    }, [location.hash]);

    useEffect(() => { 
        // Create Dynamic conditions object for filtering
        const conditions = {};
        Object.entries(filterOptions).forEach(([key, value]) => {
            //console.log(`${key} ${value}`);
            if(key === 'open' && value === true){
                conditions[key] = value
            }else if(value === true){
                conditions[key] = 'Y'
            }
        });

        
        if(targetList){

            var filteredList = targetList.filter(location => {
                return Object.keys(conditions).every(filter => {
                    return conditions[filter] === location[filter]
                });
            })
            //console.log('filtredLIst ',filteredList);
            setLoadedList(filteredList);
               
        }
        
    }, [filterOptions, targetList]);
   
    const toggleOpenNowOption = () => {
        dispatchFilter({type: 'OPEN', payload: targetList})
        //console.log('targetList ',targetList);
    }

    const toggleDriveThruOption = () => {
        dispatchFilter({type: 'DRIVE_THRU', payload: targetList})
    }

    const toggleBrewbarOption = () => {
        dispatchFilter({ type: 'BREWBAR', payload: null})
    } 

    const toggleAllFilters = (bool) => {
        dispatchFilter({ type: 'TOGGLE_ALL', payload: bool})
    }

    return (
        
    
        
        <Wrapper addClass="locations" {...props}>
            {/* <MetaInfo url={`https://web.aw.ca/${props.lang}/locations/${location}/${singleLocationInfo.restnum}`} /> */}
            <MetaInfo url={`https://web.aw.ca${location.pathname}`} />
            <div className="locations-map-wrapper"> 
                <div className="info-panel" ref={infoPanelRef}>
                    <div className={`intro-copy ${singleLocation ? 'close' : 'open'}`}>
                        {<h1><TranslatableText dictionary={{
                                en: "Find a Location",
                                fr: "Trouver un restaurant" }}>
                            </TranslatableText></h1>}

                        {props.lang === 'en' && (
                            <Fragment>
                            <p>Good food is never far away.</p>
                         
                            </Fragment>)
                        } 

                        {props.lang === 'fr' && (
                            <Fragment>
                            <p>Un vrai bon burger n’est jamais bien loin.</p>
                            </Fragment>)
                        } 
                 
                    
                            
                    </div>

                    {!singleLocation && <SearchBar 
                                            toggleOpenNowOption={toggleOpenNowOption} 
                                            checkedIsOpen={filterOptions.open} 
                                            toggleDriveThruOption={toggleDriveThruOption} 
                                            checkedWithDriveThru={filterOptions.drive_thru} 
                                            toggleBrewbarOption={toggleBrewbarOption}
                                            keepFilterOpen={keepFilterOpen}
                                            checkedBrewbarOption={filterOptions.brewbar}
                                            onChange={searchChange}
                                            lang={props.lang}
                                        />
                    }

                    {   !props.isDesktop &&
                        !singleLocation &&
                        locationCtx.userGeoPosition &&
                        targetList && 
                        loadedList !== null && <Map 
                                        {...props}
                                        //position={{lat:latitude,lng:longitude}} 
                                        position={locationCtx.userGeoPosition} 
                                        closestStore={loadedList[0]}
                                        center={mapCenter} 
                                        zoom={mapZoom} 
                                        //mouseOverLocation={mouseOverLocation}  
                                        targetList={loadedList}
                                        setTargetList={setTargetList}
                                        singleLocation={singleLocation}
                                    />
                                                             
                                            
                    }
                    
                    {singleLocationInfo && <Route 
                                            exact 
                                            path="/:lang/locations/:locationId/:locationCity/:locationName"  component={() => <SingleLocationPanel 
                                                                                                                                info={singleLocationInfo} 
                                                                                                                                lang={props.lang} 
                                                                                                                                {...props}
                                                                                                                                position={locationCtx.userGeoPosition} 
                                                                                                                                closestStore={targetList[0]}
                                                                                                                                center={mapCenter} 
                                                                                                                                zoom={mapZoom} 
                                                                                                                                //mouseOverLocation={mouseOverLocation}  
                                                                                                                                targetList={targetList}
                                                                                                                                setTargetList={setTargetList}
                                                                                                                                singleLocation={singleLocation}
                                                                                                                            >{singleLocationInfo.restnum}</SingleLocationPanel>}
                                            />
                    }
                    
                    { loadedList !== 0 && loadedList !== null ?
                        <List 
                            current={params} 
                            targetList={loadedList}
                            position={{lat:props.latitude,lng:props.longitude}} 
                            lang={props.lang}
                        />
                        :
                        null
                    }

                    {/* {targetList && loadedList !== null && <List 
                                        current={params} 
                                        targetList={targetList}
                                       
                                        position={{lat:props.latitude,lng:props.longitude}} 
                                        lang={props.lang}
                                        //onMouseEnter={onMouseEnter}
                                        //onMouseLeave={onMouseLeave}
                                    />
                    } */}
                    
                </div>

                {   props.isDesktop &&
                    targetList && 
                    loadedList !== null &&
                    locationCtx.userGeoPosition && <Map 
                                                        {...props}
                                                        className="desktop" 
                                                        position={locationCtx.userGeoPosition} 
                                                        closestStore={loadedList[0]}
                                                        center={mapCenter}  
                                                        zoom={mapZoom} 
                                                        //mouseOverLocation={mouseOverLocation}  
                                                        targetList={loadedList}
                                                        setTargetList={setTargetList}
                                                        singleLocation={singleLocation}
                                                    />
                                  
                }
            </div>

            <MobileOrderBanner lang={props.lang} />
        </Wrapper>
        
    )
}
export default memo(Locationz);