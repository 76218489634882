import React, { Component } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import MetaInfo from '../../Locations/MetaInfo/MetaInfo';
//import Drawer from '../../../components/Drawer/Drawer';
//import LocationDrawer from '../../../components/LocationDrawer/LocationDrawer';
//import ListItem from './ListItem/ListItem';
import {isRestaurantOpen, applicableHours, hasHours, is24hourDriveThru, isOnlyDriveThru, distanceOut} from '../../../shared/restaurants';
import { time } from './Hours/Hours';
import Icon from '../../../components/Icon/Icon';
import {slug } from '../../../shared/helpers';
import { tl_data } from './translations.js';

//import {getAll, target} from '../../../shared/restaurants';

class List extends Component {
    state = {
        list: (this.props.locations ?  this.props.locations : []),
        storesList: []
    }

    componentDidUpdate(){
       //console.log('props list.js ',this.props)
    }
 
    getOperationHeadline = (location, lang) => {
        
        let open_str = '';
        if (isRestaurantOpen(location) && hasHours(location)) {
            open_str = 'Open';
            var closing_at = applicableHours(location).split("-")[1];

            if (closing_at === '0'){
                //console.log('closing is 0');
                closing_at = closing_at.substring(1); 
            }
            let closingTime = time(closing_at, lang);
            open_str = <p className="status open">Open Now until {closingTime}</p>;
            //console.log('closing_at ',closing_at);

        }else{
            open_str = 'Closed';
            // If hasHours (they aren't blank)
            if(hasHours(location)){
                open_str = <p className="status closed">{tl_data['This location is currently closed'][this.props.lang]}</p>;
            }
            
        }

        return <div>{open_str}</div>
        
    }

    
    
    
    render() {
       // console.log('storesList list props',this.props.locations);
       // console.log('storesList list state',this.state.storesList);
        let reducedList = this.props.targetList.slice(0, 14);
        let restLocaList = reducedList.map(location => {
            let status = hasHours(location) ? (isRestaurantOpen(location) ? tl_data['Open'][this.props.lang] : tl_data['Closed'][this.props.lang]) : "";
            let distance = distanceOut(location.distance);
            let driveThruIcon; 
            let driveThru24hrs = is24hourDriveThru(location);
            let onlyDriveThru = isOnlyDriveThru(location);
            if(driveThru24hrs && onlyDriveThru){
                driveThruIcon =  <Icon type="front-car" colour="white"background="brown"  backgroundHover="orange" />;
            }

          //  let operatingStatus = isRestaurantOpen(location);
          // let operationHeadline = this.getOperationHeadline(location,this.props.lang);
          //  let directions =  (location.lat || location.lng) ? `${location.latitude},${location.lng}&daddr=` : 'no';
            //let openCloseStatus = location.restnum + 

            let url = "/locations/" + location.restnum;
            if(location.city_name.length > 0) {
                url += '/' + slug(location.city_name)
            } 
            url += '/' + location.slug;

            let classes = '';
            classes = this.props.current.locationId === location.restnum ? 'active' : '';
            //console.log('current ',this.props.current)
           
            return(
               <> 
            
                <li key={location.restnum} data-location={url} >
                    <Link 
                        to={'/'+this.props.lang+url} 
                        className={`${classes} link-wrap`} 
                        // onMouseEnter={(e) => this.props.onMouseEnter(location)}
                        // onMouseLeave={(e) => this.props.onMouseLeave()}
                    >

                        <h4 ref="title" title={location.address1}>
                            <span className="fade"></span>
                            <Icon type="pin" colour="brown" hover="orange" />
                            {location.address1}
                        </h4>

                        <div className="details">
                            <span className="fade"></span>
                            <span className="driveIcon">{driveThruIcon}</span>
                            <span className={"status " + status.toLowerCase()}>{status}</span> 
                            <span className="distance">{distance} km</span> 
                            <Icon type="plus-inverted open" colour="white" background="brown"  backgroundHover="orange" />
                        </div>

                    </Link>
                </li>
                </>);
                
        })

        return (
            <div className="restaurant-locations-list">
                <ul>
                {restLocaList}
                </ul>
            </div>
        );
    }
}

export default List;